<template>
  <BaseElSelect v-model="syncModel" placeholder="請選擇" clearable>
    <BaseElSelectOption
      v-for="nav in allowOptionList"
      :key="nav.value"
      :label="nav.name"
      :value="nav.value"
    />
  </BaseElSelect>
</template>

<script>
import { navConfig } from '@/config/memberCenter'
import { find, map, filter } from 'lodash'
export default {
  name: 'NavItemSelect',
  props: ['model', 'selected', 'allowNavFooter'],
  data: () => ({
    navConfig,
    allowOptionList: [],
  }),
  computed: {
    syncModel: {
      get () {
        return this.model
      },
      set (val) {
        this.$emit('update:model', val)
      },
    },
  },

  mounted () {
    this.allowOptionList = this.filterOptions()
  },
  methods: {
    disabledSelected (nav) {
      return Boolean(find(this.selected, (item) => item === nav.value))
    },

    filterOptions () {
      const config = map(this.allowNavFooter, 'type')
      const data = filter(this.navConfig, (nav) => config.includes(nav.value))
      return data
    },
  },
}
</script>

<style lang="postcss" scoped></style>
