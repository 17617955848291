<template>
  <section class="section-block">
    <SectionTitle title="聯繫客服連結" @edit="showEditDialog = true" />
    <BaseElForm>
      <BaseElFormItem label="聯繫方式">
        <!-- {{ customerServiceConfig[formData.type]? customerServiceConfig[formData.type].name : '尚未設置' }} -->
        <!-- {{ customerServiceConfig[configCustomerLinkType].name || '尚未填寫' }} -->
        {{ configCustomerLinkTypeTranslate }}
        <!-- {{ currentLink? currentLink : '尚未填寫' }} -->
      </BaseElFormItem>

      <BaseElFormItem
        v-if="configCustomerLinkType === 'lineId'"
        prop="lineId"
        label="LineID"
      >
        {{ configContactCustomerLink.lineId }}
      </BaseElFormItem>

      <BaseElFormItem
        v-if="configCustomerLinkType === 'phone'"
        prop="phone"
        label="電話號碼"
      >
        {{ configContactCustomerLink.phone }}
      </BaseElFormItem>

      <BaseElFormItem
        v-if="configCustomerLinkType === 'email'"
        prop="email"
        label="電子信箱"
      >
        {{ configContactCustomerLink.email }}
      </BaseElFormItem>

      <BaseElFormItem v-if="configCustomerLinkType === 'url'" prop="url" label="網址">
        {{ configContactCustomerLink.url }}
      </BaseElFormItem>
    </BaseElForm>

    <el-dialog
      title="編輯聯繫方式"
      :visible="showEditDialog"
      :close-on-click-modal="false"
      @close="showEditDialog = false"
    >
      <BaseElForm ref="form" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem prop="type" label="聯繫方式">
          <BaseElSelect v-model="formData.type" placeholder="請選擇" @change="resetData(false)">
            <BaseElSelectOption
              v-for="service in customerServiceConfig"
              :key="service.value"
              :label="service.name"
              :value="service.value"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <BaseElFormItem v-if="formData.type === 'lineId'" prop="lineId" label="LineID">
          <BaseElInput v-model="formData.lineId" placeholder="請輸入" maxlength="50" show-word-limit />
        </BaseElFormItem>

        <BaseElFormItem v-if="formData.type === 'phone'" prop="phone" label="電話號碼">
          <BaseElInput v-model="formData.phone" placeholder="請輸入" maxlength="20" show-word-limit />
        </BaseElFormItem>

        <BaseElFormItem v-if="formData.type === 'email'" prop="email" label="電子信箱">
          <BaseElInput v-model="formData.email" placeholder="請輸入" maxlength="255" show-word-limit />
        </BaseElFormItem>

        <BaseElFormItem v-if="formData.type === 'url'" prop="url" label="網址">
          <BaseElInput v-model="formData.url" placeholder="請輸入" maxlength="255" show-word-limit />
        </BaseElFormItem>
      </BaseElForm>

      <section class="text-right">
        <BaseElButton plain @click="showEditDialog = false, resetForm()">取消</BaseElButton>
        <BaseElButton type="primary" @click="updateShopInfo">儲存</BaseElButton>
      </section>
    </el-dialog>
  </section>
</template>

<script>
import { customerServiceConfig } from '@/config/memberCenter'
import { UpdateShopInfo, GetShopInfo } from '@/api/member/memberCenter'
import { noEmptyRules, urlRules, emailRules } from '@/validation'
import { mapGetters } from 'vuex'
import SectionTitle from '@/components/Title/SectionTitle'
import formUtils from '@/utils/form'
import { get } from 'lodash'

export default {
  name: 'ContactUsLink',
  components: {
    SectionTitle,
  },
  data: () => ({
    showEditDialog: false,
    customerServiceConfig,
    config: null,
    formData: {
      type: '',
      lineId: '',
      phone: '',
      email: '',
      url: '',
    },
    formRules: {
      type: [noEmptyRules()],
      lineId: [noEmptyRules()],
      phone: [noEmptyRules()],
      email: [noEmptyRules(), emailRules()],
      url: [noEmptyRules(), urlRules()],
    },
  }),

  computed: {
    ...mapGetters(['shop']),
    configCustomerLinkTypeTranslate () {
      return get(this.customerServiceConfig, `${this.configCustomerLinkType}.name`, '尚未填寫')
    },
    configCustomerLinkType () {
      if (!this.config) return {}
      return get(this.config.contactCustomerLink, 'type', {})
    },
    configContactCustomerLink () {
      return get(this.config, 'contactCustomerLink', {})
    },
    contactCustomerLink () {
      const data = {
        type: this.formData.type,
      }

      for (const key in this.formData) {
        if (key !== 'type') {
          if (this.formData[key]) {
            data[key] = this.formData[key]
          }
        }
      }

      return data
    },
    currentLink () {
      if (this.contactCustomerLink) {
        const type = this.contactCustomerLink.type
        return this.contactCustomerLink[type]
      }

      return null
    },
  },

  async mounted () {
    await this.getShopInfo()
  },
  methods: {
    async updateShopInfo () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      const [res, err] = await UpdateShopInfo({
        shopId: this.shop,
        contactCustomerLink: this.contactCustomerLink,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
      this.$emit('update')
      await this.getShopInfo()
      this.showEditDialog = false
      this.resetForm()
    },

    async getShopInfo () {
      const [res, err] = await GetShopInfo({ shopId: this.shop })
      if (err) return this.$message.error(err)
      if (!res) return
      this.config = res
      for (const key in res.contactCustomerLink) {
        this.formData[key] = res.contactCustomerLink[key]
      }
    },

    resetData (clear = false) {
      if (clear) {
        this.resetForm()
        return
      }

      this.$refs.form.clearValidate()
      for (const item in this.formData) {
        if (this.config.contactCustomerLink[item] && item !== 'type') {
          this.formData[item] = this.config.contactCustomerLink[item]
        } else if (!this.config.contactCustomerLink[item] && item !== 'type') { this.formData[item] = '' }
      }
    },

    resetForm () {
      formUtils.resetForm(this.$refs.form)
      for (const item in this.formData) {
        if (this.config.contactCustomerLink[item]) { this.formData[item] = this.config.contactCustomerLink[item] } else if (!this.config.contactCustomerLink[item] && item !== 'type') { this.formData[item] = '' }
      }
    },
  },
}
</script>
