<template>
  <el-dialog
    title="底部快捷功能設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm
      ref="form"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem label="排序1">
        <span style="padding-left: 20px">會員中心</span>
      </BaseElFormItem>

      <!-- 排序 2 -->
      <div>
        <BaseElFormItem label="排序2" prop="nav2.type">
          <NavItemSelect
            :allowNavFooter="allowNavFooter"
            :model.sync="formData.nav2.type"
            :selected="formData"
            :error="checkDuplicate(formData.nav2.type)"
          />
        </BaseElFormItem>
        <BaseElForm
          v-if="formData.nav2.type === 'otherLink'"
          ref="navForm2"
          :model="formData"
          :rules="navLinkRules"
        >
          <BaseElFormItem prop="nav2.name">
            <BaseElInput
              v-model="formData.nav2.name"
              maxlength="5"
              show-word-limit
              placeholder="請輸入連結名稱"
            />
          </BaseElFormItem>

          <BaseElFormItem prop="nav2.link.url">
            <BaseElInput
              v-model="formData.nav2.link.url"
              maxlength="255"
              show-word-limit
              placeholder="請輸入連結網址"
            />
          </BaseElFormItem>
        </BaseElForm>
      </div>

      <!-- 排序 3 -->
      <div>
        <BaseElFormItem
          label="排序3"
          prop="nav3.type"
          :error="checkDuplicate(formData.nav3.type)"
        >
          <NavItemSelect
            :allowNavFooter="allowNavFooter"
            :model.sync="formData.nav3.type"
            :selected="formData"
          />
        </BaseElFormItem>
        <BaseElForm
          v-if="formData.nav3.type === 'otherLink'"
          ref="navForm3"
          :model="formData"
          :rules="navLinkRules"
        >
          <BaseElFormItem prop="nav3.name">
            <BaseElInput
              v-model="formData.nav3.name"
              maxlength="5"
              show-word-limit
              placeholder="請輸入連結名稱"
            />
          </BaseElFormItem>

          <BaseElFormItem prop="nav3.link.url">
            <BaseElInput
              v-model="formData.nav3.link.url"
              maxlength="255"
              show-word-limit
              placeholder="請輸入連結網址"
            />
          </BaseElFormItem>
        </BaseElForm>
      </div>

      <!-- 排序 4 -->
      <div>
        <BaseElFormItem
          label="排序4"
          prop="nav4.type"
          :error="checkDuplicate(formData.nav4.type)"
        >
          <NavItemSelect
            :allowNavFooter="allowNavFooter"
            :model.sync="formData.nav4.type"
            :selected="formData"
          />
        </BaseElFormItem>
        <BaseElForm
          v-if="formData.nav4.type === 'otherLink'"
          ref="navForm4"
          :model="formData"
          :rules="navLinkRules"
        >
          <BaseElFormItem prop="nav4.name">
            <BaseElInput
              v-model="formData.nav4.name"
              maxlength="5"
              show-word-limit
              placeholder="請輸入連結名稱"
            />
          </BaseElFormItem>

          <BaseElFormItem prop="nav4.link.url">
            <BaseElInput
              v-model="formData.nav4.link.url"
              maxlength="255"
              show-word-limit
              placeholder="請輸入連結網址"
            />
          </BaseElFormItem>
        </BaseElForm>
      </div>

      <!-- 排序 5 -->
      <div>
        <BaseElFormItem
          label="排序5"
          prop="nav5.type"
          :error="checkDuplicate(formData.nav5.type)"
        >
          <NavItemSelect
            :allowNavFooter="allowNavFooter"
            :model.sync="formData.nav5.type"
            :selected="formData"
          />
        </BaseElFormItem>
        <BaseElForm
          v-if="formData.nav5.type === 'otherLink'"
          ref="navForm5"
          :model="formData"
          :rules="navLinkRules"
        >
          <BaseElFormItem prop="nav5.name">
            <BaseElInput
              v-model="formData.nav5.name"
              maxlength="5"
              show-word-limit
              placeholder="請輸入連結名稱"
            />
          </BaseElFormItem>

          <BaseElFormItem prop="nav5.link.url">
            <BaseElInput
              v-model="formData.nav5.link.url"
              maxlength="255"
              show-word-limit
              placeholder="請輸入連結網址"
            />
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="handleSave">儲存</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import NavItemSelect from '@/components/Select/NavItemSelect.vue'
import { navFooterErrorCode } from '@/config/memberCenter'
import formUtils from '@/utils/form'
import { forEach, filter, find } from 'lodash'
import { noEmptyRules, urlRules } from '@/validation'

export default {
  name: 'NavFooterSettingDialog',
  components: { NavItemSelect },
  props: ['navData', 'customerLink', 'allowNavFooter'],
  data () {
    return {
      navFooterErrorCode,
      formData: {
        nav2: {
          type: '',
          name: '',
          link: {
            url: '',
          },
        },
        nav3: {
          type: '',
          name: '',
          link: {
            url: '',
          },
        },
        nav4: {
          type: '',
          name: '',
          link: {
            url: '',
          },
        },
        nav5: {
          type: 'fewfewfew',
          name: '',
          link: {
            url: '',
          },
        },
      },

      formRules: {
        'nav2.type': [this.repeatRules(), noEmptyRules()],
        'nav3.type': [this.repeatRules()],
        'nav4.type': [this.repeatRules()],
        'nav5.type': [this.repeatRules()],
      },

      navLinkRules: {
        'nav2.name': [noEmptyRules('請輸入連結名稱')],
        'nav2.link.url': [noEmptyRules('請輸入連結網址'), urlRules()],
        'nav3.name': [noEmptyRules('請輸入連結名稱')],
        'nav3.link.url': [noEmptyRules('請輸入連結網址'), urlRules()],
        'nav4.name': [noEmptyRules('請輸入連結名稱')],
        'nav4.link.url': [noEmptyRules('請輸入連結網址'), urlRules()],
        'nav5.name': [noEmptyRules('請輸入連結名稱')],
        'nav5.link.url': [noEmptyRules('請輸入連結網址'), urlRules()],
      },
    }
  },

  computed: {
    navFooters () {
      const footers = [{ type: 'memberCenter' }]
      const others = filter(this.formData, (nav) => nav !== '')
      forEach(others, (nav) => {
        console.log('1', nav)
        if (nav.type === 'otherLink') {
          footers.push({
            name: nav.name,
            type: nav.type,
            link: {
              type: 'url',
              url: nav.link.url,
            },
          })
        } else if (nav.type) footers.push({ type: nav.type })
      })
      return footers
    },
  },
  mounted () {
    this.formData = JSON.parse(JSON.stringify(this.navData))
  },
  methods: {
    hasTypeDuplicates () {
      return this.navFooters.some((one, index) => {
        return (
          this.navFooters.findIndex(
            (o) => o.type !== 'otherLink' && o.type === one.type,
          ) !== index
        )
      })
    },

    async handleSave () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      if (this.$refs.navForm2) {
        if (!(await formUtils.checkForm(this.$refs.navForm2))) return
      }
      if (this.$refs.navForm3) {
        if (!(await formUtils.checkForm(this.$refs.navForm3))) return
      }
      if (this.$refs.navForm4) {
        if (!(await formUtils.checkForm(this.$refs.navForm4))) return
      }
      if (this.$refs.navForm5) {
        if (!(await formUtils.checkForm(this.$refs.navForm5))) return
      }
      this.$emit('save', this.navFooters)
    },

    checkDuplicate (data) {
      const res = filter(
        this.navFooters,
        (nav) => nav.type !== 'otherLink' && nav.type === data,
      )
      if (res.length >= 2) return '請勿重複使用相同功能'
      return ''
    },

    checkRepeat (rule, value, callback) {
      this.checkNavError(value, callback)

      if (this.checkDuplicate(value)) {
        return callback(new Error('請勿重複使用相同功能'))
      } else {
        return callback()
      }
    },

    checkNavError (value, callback) {
      if (!value) return true
      const navData = find(this.allowNavFooter, { type: value })
      console.log(value, navData)
      if (navData.error) {
        callback(new Error(this.navFooterErrorCode[navData.errorCode].msg))
        return false
      }
    },

    repeatRules () {
      return {
        validator: this.checkRepeat,
        trigger: 'change',
      }
    },
  },
}
</script>

<style scoped lang="postcss">
::v-deep .el-form-item__label {
  @apply pb-0;
}
</style>
