<template>
  <section class="section-block">
    <SectionTitle title="會員中心色系主題設定" @edit="$router.push({name: 'MemberCenterColorThemeSetting'})" />
    <BaseElForm label-width="200px" label-position="left">
      <BaseElFormItem label="目前主題">
        {{ displayData.theme }}
      </BaseElFormItem>
    </BaseElForm>
  </section>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import { useFetch } from '@/use/fetch'
import { GetClientPageConfig } from '@/api/member/memberCenter'
import { get } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberCenterColorStyle',
  emits: ['refresh'],
  setup (props, { emit }) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()

    const configData = ref({})

    const displayData = computed(() => {
      const data = configData.value
      let theme = data.colorStyle
      if (theme === 'custom') {
        theme = get(data, 'CustomColorStyle.name')
      }
      return {
        theme,
      }
    })

    onMounted(async () => {
      simpleFetch(GetClientPageConfig, { shopId: shopId.value }, (res) => {
        configData.value = res
      })
    })

    return { configData, displayData }
  },
})
</script>

<style lang="postcss" scoped>

</style>
