<template>
  <section class="area-wrapper section-block">
    <SectionTitle title="會員卡顯示設定" @edit="showEditModal = true" />
    <!-- <p class="container-title mb-[24px]">基本設定</p> -->
    <BaseElForm label-width="200px" label-position="left">
      <BaseElFormItem label="顯示設定（至多三個）">
        {{ displayData }}
      </BaseElFormItem>
    </BaseElForm>

    <MemberCardShowEditModal
      v-if="showEditModal"
      :configData="configData"
      @close="showEditModal = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import MemberCardShowEditModal from './components/MemberCardShowEditModal.vue'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { get, map, filter } from 'lodash'

export default {
  name: 'MemberCardShow',
  components: {
    SectionTitle,
    MemberCardShowEditModal,
  },
  props: {
    configData: Array,
  },
  data: () => ({
    showEditModal: false,
    config: {
      wallet: false,
      coupon: false,
      address: false,
    },
    showType: {
      walletBalance: { label: '儲值金', value: 'walletBalance' },
      couponCount: { label: '票券', value: 'couponCount' },
      cashbackBalance: { label: '回饋金', value: 'cashbackBalance' },
      pointBalance: { label: '點數', value: 'pointBalance' },
      classTicketRecordCount: { label: '堂票', value: 'classTicketRecordCount' },
      posPalMemberBalance: { label: '會員餘額', value: 'posPalMemberBalance' },
    },
  }),
  computed: {
    ...mapGetters(['userPlanFeature', 'userFeatures']),
    useAddress () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'adminView.memberCenterConfig.userInfoMoreAddress')
    },
    displayData () {
      const showTypes = ['walletBalance', 'couponCount', 'cashbackBalance', 'pointBalance', 'classTicketRecordCount', 'posPalMemberBalance']
      let enableConfig = filter(this.configData, config => config.enable)
      enableConfig = filter(enableConfig, i => showTypes.includes(i.type))
      enableConfig.sort((a, b) => a.order - b.order)
      const typesLabels = map(enableConfig, i => get(this.showType, `${i.type}.label`))
      if (!typesLabels.length) return '尚未設置'
      return typesLabels.join('、')
    },
  },
}
</script>

<style>

</style>
