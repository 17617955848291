<template>
  <div v-loading="loading" class="external-member-center section-block">
    <SectionTitle title="外部系統會員卡" @edit="showEditModal = true" />
    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem label="啟用外部系統會員卡">{{ configData.enable ? '啟用' : '關閉' }}</BaseElFormItem>
      <BaseElFormItem label="外部系統廠商">{{ get(memberCenterQRCodeSourceConfig, `${configData.externalType}.label`) }}</BaseElFormItem>
      <BaseElFormItem label="會員條碼類型">QR Code</BaseElFormItem>
    </BaseElForm>

    <ExternalMemberCenterEditModal
      v-if="showEditModal"
      :configData="configData"
      @close="showEditModal = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed, set, onMounted } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import ExternalMemberCenterEditModal from './ExternalMemberCenterEditModal.vue'
import { GetMemberCenterQRCode } from '@/api/member/memberCenter'
import store from '@/store'
import { get } from 'lodash'
import { memberCenterQRCodeSourceConfig } from '@/config/memberCenter'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ExternalMemberCenter',
  components: { SectionTitle, ExternalMemberCenterEditModal },
  setup (props) {
    const { checkAction } = usePermissions()
    const shopId = computed(() => store.getters.shop)
    const loading = ref(false)
    const showEditModal = ref(false)
    const configData = reactive({})
    const displayData = computed(() => {
      return {}
    })

    const getMemberCenterQRCode = async () => {
      const [res, err] = await GetMemberCenterQRCode({ shopId: shopId.value })
      if (err) return window.$message.error(err)
      for (const key in res) {
        set(configData, key, res[key])
      }
    }

    const refresh = async () => {
      loading.value = true
      await getMemberCenterQRCode()
      loading.value = false
    }

    onMounted(async () => {
      refresh()
    })

    return { loading, configData, displayData, showEditModal, refresh, get, memberCenterQRCodeSourceConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
